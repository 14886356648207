:root {
    --main-color: #FFF;
    --main-color-hover: #6875d933;
    --track-color: #FFF;
}

.customSlider {
    max-width: 300px;
    margin: auto;
}

.customSlider-track {
    top: 8px;
    height: 4px;
    background: #64748b;
}

.customSlider-track.customSlider-track-0 {
    background: #F7C01D !important;
}

.customSlider>.thumb {
    width: 20px;
    height: 20px;
    background: #F7C01D;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #F7C01D;
    cursor: pointer;
}